export const projects = [
  {
    title: "Construction Software Application Development",
    subtitle: "Front-End using React and CSS",
    description:
      "Contributing to the development of a full-stack construction software application aimed at optimizing the home-building process.Collaborating with the front-end team to create a user-friendly interface using React.js and CSS. Backend utilized Replicache for offline data synchronization and Prisma, Postgres, and Aurora for the database",
    link: "",
  },
  {
    title: "React Websites for Amazon Inc.",
    subtitle: "React-based Websites",
    description:
      "Played a pivotal role in a high visibility team that built full stack applications for supply chain transportation operations for sort centers. Developed UI for labor planning, shift planning, roster generation and performance tracking using React and leveraged AWS services such as Lambda, Aurora, SNS, SQS, and CloudFormation.",
    link: "",
  },
  {
    title: "AWS for Amazon Inc.",
    subtitle: "Micro Services, Cloud Architecture",
    description:
      "Facilitated the migration of JSON data with more than 14 million records inserted in 15 minutes time to be used across the country in more than 50 sort centers - to manage more than 50k employees. Experience in microservices, cloud infrastructure, AWS. Demonstrated ability to develop and maintain large-scale projects.",
    link: "",
  },
  {
    title: "Office Automation Software",
    subtitle: "Lotus Domino Designer",
    description:
      "Created office automation software using IBM Domino Designer for New Wave Pvt. Ltd., facilitating inventory management and scheduling of software device deliveries. Export Control Program enforcement application for Socrates Pvt. Ltd. Led test environment setup, manage test processes, bug tracking and reporting.",
    link: "",
  },
  {
    title: "Website",
    subtitle: "WordPress",
    description:
      "Ecommerce Website, for the non-profit KBWC Outreach in Stone Mountain, Georgia",
    link: "https://www.kbwc.org",
  },
  {
    title: "Export Control Program enforcement application",
    subtitle: "Lotus Workflow",
    description:
      "Worked with a team to design and develop the software for Socrates Pvt. Ltd, using Lotus Workflow to check the Bill of Materials of projects against controlled lists in order to red-flag transactions that include banned items",
    link: "",
  },
  {
    title: "Help Trust",
    subtitle: "WordPress",
    description:
      "A website that focuses on getting underprivileged children the resources and support for basic education and skill. A non-profit that believes education must be free.",
    link: "http://www.helptrust.org",
  },
];
export const skills = [
  "JavaScript",
  "TypeScript",
  "React js",
  "CSS",
  "Tailwind CSS",
  "HTML",
  "Java",
  "Struts",
  "Spring Framework",
  "Hibernate",
  "SQL",
  "Wordpress",
  "Software Testing",
];
